<div class="container-box">
  <div class="tab-bar">
    <nz-tabset nzLinkRouter [(nzSelectedIndex)]="tabIndex">
      <nz-tab *ngFor="let tab of tabs">
          <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tab: tab.key }" queryParamsHandling="merge">
            <nz-badge [nzCount]="tab?.total" [nzOverflowCount]="999" nzShowZero [nzStyle]="{ backgroundColor: '#1b1ac4', marginLeft: '10px'}">
              <span>{{ tab.title }}</span>
            </nz-badge>
          </a>
        <ng-template nz-tab>
          <ng-container *ngComponentOutlet="tab.component"></ng-container>         
        </ng-template>
      </nz-tab>
    </nz-tabset>
  </div>
</div>