import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { DialogsModule } from '@dialogs/dialogs.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SharedModule } from '@app/admin/shared/shared.module';
import { UiCommonModule } from '@app/admin/components/common/module';
import { DenimRoutingModule } from './denim.routing';
import { DenimList } from '.';
import { NonFactored } from './components/non-factored';
import { DrawerModule } from '@app/drawers/drawer.module';
import { SelectBySearchingModule } from '@libs/select-client/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ScrollingModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzTagModule,
    NzSwitchModule,
    NzDropDownModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTypographyModule,
    DialogsModule,
    SharedModule,
    SelectBySearchingModule,
    UiCommonModule,
    NzSpinModule,
    DenimRoutingModule,
    DrawerModule,
  ],
  declarations: [
    DenimList,
    NonFactored
  ],
  exports: [
  ],
  providers: [
  ]
})
export class DenimModule {}
