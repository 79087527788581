<div class="container-box">
  <div class="header">
    <div class="filterbox">
      <div class="filter-item search">
        <div class="label">&nbsp;</div>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input type="text" nz-input placeholder="Search" [(ngModel)]="presentSearch"
            (ngModelChange)="onSearchChange()">
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>
      </div>
      <div class="filter-item">
        <div class="label">Customer</div>
        <select-by-searching style="min-width: 250px;" [version]="2" nzMode="multiple" [(ngModel)]="presentClientIds"
          (modelChange)="onSearchChange()" dropdownClassName="finance-filter-customer">
        </select-by-searching>
      </div>
      <div class="filter-item">
        <div class="label">Except Customer</div>
        <select-by-searching style="min-width: 250px; max-width: 500px;" [version]="2" nzMode="multiple" [(ngModel)]="exceptClientIds"
          (modelChange)="onSearchChange()" dropdownClassName="finance-filter-customer">
        </select-by-searching>
      </div>
      <div class="filter-item">
        <div class="label">From Date</div>
        <nz-form-control>
          <nz-input-group [nzAddOnAfter]="getTimezoneShort()">
            <nz-date-picker [(ngModel)]="filterFromDate" nzFormat="yyyy-MM-dd" (ngModelChange)="onSearchChange()">
            </nz-date-picker>
          </nz-input-group>
        </nz-form-control>
      </div>
      <div class="filter-item">
        <div class="label">To Date</div>
        <nz-form-control>
          <nz-input-group [nzAddOnAfter]="getTimezoneShort()">
            <nz-date-picker [(ngModel)]="filterToDate" nzFormat="yyyy-MM-dd" (ngModelChange)="onSearchChange()">
            </nz-date-picker>
          </nz-input-group>
        </nz-form-control>
      </div>
      <div class="filter-item">
        <div class="label">&nbsp;</div>
        <button (click)="onBtnReset()" nz-button>
          Reset
        </button>
      </div>
      <div class="filter-item">
        <div class="label">&nbsp;</div>
        <button (click)="onBtnExport()" [nzLoading]="isExporting" nz-button>
          <i nz-icon nzTheme="outline" nzType="file-excel"></i>
          Export
        </button>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="list">
      <nz-table #nzTable (nzPageIndexChange)="onDataListPageChanged($event)" [nzData]="listData"
        [nzFrontPagination]="false" [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
        [nzPageSize]="limit" [nzPageIndex]="pageIndex" [nzShowPagination]="totalCount > listData.length"
        [nzTotal]="totalCount" nzBordered nzSize="small" [nzScroll]="{y: tableHeight+'px'}">
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="search"></i>No data</div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
        </ng-template>
        <thead>
          <tr>
            <th nzWidth="250px">Customer Name</th>
            <th nzWidth="150px">Order ID</th>
            <th nzWidth="120px">Amount</th>
            <th nzWidth="300px">Validation</th>
            <th nzWidth="200px">Status</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of listData; let i = index">
            <tr>
              <td>{{data.customerName}}</td>
              <td>
                <a [href]="getOrderLink(data)" target="_blank">{{data.warpId}}</a>
              </td>
              <td nzAlign="right">{{data.amount}}</td>
              <td>
                <div *ngIf="data.isLoadingValidation" class="center-children">
                  <nz-spin nzSimple></nz-spin>
                </div>
                <div *ngIf="!data.isLoadingValidation && data.validation" class="">
                  <div *ngIf="data.validation.isValid">
                    <div class="status">
                      <span nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span>
                      <div *ngIf="data.validation.invoice" class="left5">
                        <a (click)="downloadInvoice({invoice: data.validation.invoice})">
                          <i *ngIf="!data.validation.invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
                            class="right5"></i>
                          <i *ngIf="data.validation.invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                            class="right5"></i>Download invoice
                        </a>
                        <a nz-button nzSize="small" nzType="link" nz-tooltip="" nzTooltipTitle="Re-generate Invoice"
                          (click)="onDoGenerateInvoice(data)" [nzLoading]="data.isGeneratingInvoice">
                          <span nz-icon nzType="reload" nzTheme="outline"></span>
                        </a>
                      </div>
                      <div *ngIf="!data.validation.invoice" class="left5">
                        <a (click)="onBtnGenerateInvoice(data)">
                          <i *ngIf="data.isGeneratingInvoice" nz-icon nzType="loading" nzTheme="outline"
                            class="right5"></i>Generate invoice
                        </a>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!data.validation.isValid">
                    <div class="status">
                      <span nz-icon [nzType]="'close-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'red'"></span>
                      <div class="text error">{{data.validation.msg}}</div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="finance-non-factored-select-status" [class]="'status-'+data.denimStatus">
                  <nz-select nzPlaceHolder="Select a status" [(ngModel)]="data.denimStatus" style="min-width: 120px;"
                    (ngModelChange)="onStatusChanged(data.id, $event)">
                    <nz-option *ngFor="let status of denimStatusArray" [nzLabel]="status.label"
                      [nzValue]="status.value"></nz-option>
                  </nz-select>
                </div>
                <div class="changeLog" *ngIf="data.changeLogs?.['status']">
                  when {{data.changeLogs['status']?.when}} by {{getFullName(data.changeLogs['status']?.user)}}
                </div>
              </td>
              <td>
                <p nz-typography nzEditable [(nzContent)]="data.denimNote"
                  (nzContentChange)="onNoteChanged(data.id, $event)"></p>
                <div class="changeLog" *ngIf="data.changeLogs?.['note']">
                  when {{data.changeLogs['note']?.when}} by {{getFullName(data.changeLogs['note']?.user)}}
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>