import { Component } from "@angular/core";
import { getDashboard } from "@services/index";
import { BaseComponent } from "@abstract/BaseComponent";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { NonFactored } from "./components/non-factored";

@Component({
  selector: 'denim-list',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class DenimList extends BaseComponent {
  public tabIndex = -1;
  public presentSearch = null;
  public subWindow = null;
  public noPod = true;
  public presentPod;
  public url : string;
  public tabs = [
    {
      key: 'non-factored',
      title: 'Non Factored',
      component: NonFactored
    }
  ];
  constructor(public activatedRoute: ActivatedRoute){
    super();
  }
  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    const {tab} = this.queryParams;
    if (!tab || !this.isTabValid(tab)) {
      return this.routeWithQueryUrl({tab: this.tabs[0].key});
    }
    let index = this.tabKeyToIndex(tab);
    if (this.tabIndex != index) {
      this.tabIndex = index;
    }
  }

  private isTabValid(key: string): boolean {
    return this.tabs.filter(it => it.key == key).length == 1;
  }

  private tabKeyToIndex(key: string): number {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].key == key) {
        return i;
      }
    }
    return 0;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
