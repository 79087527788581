import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DenimList } from '.';

const denimRoutes: Routes = [
  {
    path: '', component: DenimList, data: { title: 'Non Factored - WARP Admin' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(denimRoutes)],
  exports: [RouterModule]
})
export class DenimRoutingModule { }
